var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "alarmsNumber", staticClass: "alarms-number" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "functionButtons",
          staticClass: "function-buttons"
        },
        [_c("el-button", { attrs: { type: "primary", size: "small" } })],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "analysis-form",
            {
              attrs: {
                mistakeList: _vm.mistakeList,
                mistakeShow: true,
                platformAlarmShow: true
              },
              on: { getFormVal: _vm.onSubmit }
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.tableData.length === 0,
                    loading: _vm.downLoadStatus
                  },
                  on: {
                    click: function($event) {
                      return _vm.exportData(
                        "/illegalExport/alarmTypeStatisticsExport"
                      )
                    }
                  }
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.tableData.length === 0,
                    loading: _vm.downLoadStatus
                  },
                  on: {
                    click: function($event) {
                      return _vm.exportData(
                        "/illegalExport/alarmTypePerStatisticsExport"
                      )
                    }
                  }
                },
                [_vm._v("导出统计表")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              "show-overflow-tooltip": true,
              width: 120 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              width: 100 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "licensePlateColor",
              label: "车牌颜色",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.colorObj[Number(scope.row.licensePlateColor)])
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceNos",
              label: "终端号",
              width: 100 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "speed", label: "超速报警" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "platformSpeed",
              width: 110 * _vm.screenCoefficient,
              label: "路网图超速报警"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "fatigue",
              width: 120 * _vm.screenCoefficient,
              label: "单次超时疲劳数量"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "workFatigue",
              width: 120 * _vm.screenCoefficient,
              label: "累计超时疲劳数量"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sleepFatigue",
              width: 110 * _vm.screenCoefficient,
              label: "生理疲劳数量"
            }
          }),
          _c("el-table-column", { attrs: { prop: "smoke", label: "抽烟" } }),
          _c("el-table-column", {
            attrs: {
              prop: "call",
              label: "接打手机电话报警",
              width: 140 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "distracted",
              label: "长时间不目视前方",
              width: 140 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "frontalCollision",
              label: "前向碰撞报警",
              width: 110 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trackDeviate",
              label: "车道偏离报警",
              width: 110 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "headwayClose",
              label: "车距过近报警",
              width: 110 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "noDetectionDriver",
              label: "未检测到驾驶员",
              width: 140 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "detectionFunction",
              label: "驾驶员检测功能失效",
              width: 150 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driveAssist",
              label: "驾驶员辅助功能失效报警",
              width: 180
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }